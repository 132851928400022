import merge from "lodash/merge";
import get from "lodash/get";
import applyShadow from "@ui/utils/applyShadow";
import BaseThemeProps from "src/themes/baseTheme/baseThemeProps";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";

class SenseThemeProps extends BaseThemeProps {
  constructor() {
    super();
    this.defaultThemeProps = new DefaultThemeProps();
  }

  getSelectionBarProps(_theme) {
    return this.defaultThemeProps.getSelectionBarProps(_theme);
  }

  getHeaderProps(theme) {
    return {
      root: {
        backgroundColor: theme.v2.color.surface,
        borderBottomColor: "transparent",
        fontFamily: theme.v2.typography.title4.family,
        color: theme.v2.color.onSurface,
        fontWeight: theme.v2.typography.title4.weight,
        fontSize: theme.v2.typography.title4.size,
        padding: {
          lg: "0 32px",
          md: "0 32px 0 16px",
          sm: "0 16px",
        },
        letterSpacing: "2px",
        textTransform: theme.v2.typography.title4.textCase,
        height: {
          lg: "88px",
          md: "88px",
          sm: "78px",
        },
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderLoginButtonProps(theme) {
    const loginButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.loginButton")),
    };
    return {
      textTransform: loginButtonTheme.v2.typography.title4.textCase,
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.searchButton")),
    };
    return {
      color: searchButtonTheme.v2.color.primary,
    };
  }

  getHeaderCartButtonProps(theme) {
    return {
      cartText: {
        fontFamily: theme.v2.typography.title4.family,
        fontWeight: theme.v2.typography.title4.weight,
        fontSize: theme.v2.typography.title4.size,
        textTransform: theme.v2.typography.title4.textCase,
      },
    };
  }

  getHeaderCartBadgeProps(theme) {
    return {
      color: theme.v2.color.onError,
      backgroundColor: theme.v2.color.error,
      position: "absolute",
      fontSize: theme.v2.typography.title6.size,
      letterSpacing: "0",
      top: {
        lg: "-8px",
        md: "-8px",
        sm: "-8px",
      },
      right: {
        lg: "-24px",
        md: "-24px",
        sm: "-14px",
      },
      width: {
        lg: "20px",
        md: "20px",
        sm: "16px",
      },
      height: {
        lg: "20px",
        md: "20px",
        sm: "16px",
      },
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.deliveryBanner)};

    return {
      root: {
        backgroundColor: theme.v2.color.base.grey["100"],
        fontFamily: theme.v2.typography.title4.family,
        color: theme.v2.color.onSurface,
        fontWeight: theme.v2.typography.title4.weight,
        fontSize: {
          lg: theme.v2.typography.title4.size.lg,
          md: theme.v2.typography.title4.size.lg,
          sm: theme.v2.typography.title4.size.md,
        },
        locationText: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: {
            lg: theme.v2.typography.bodyText2.size.lg,
            md: theme.v2.typography.bodyText2.size.lg,
            sm: theme.v2.typography.bodyText2.size.md,
          },
        },
      },
    };
  }

  getDeliveryModePickerProps(_theme, showInventoryPicker) {
    const theme = {
      v2: merge(
        {},
        _theme.v2,
        get(_theme.v2, "overrides.deliveryBanner.deliveryModePicker")
      ),
    };

    return {
      backgroundColor: "white",
      color: theme.v2.color.onSurface,
      hoverSelectedBackgroundColor: theme.v2.color.primaryVariant,
      pickupColor: theme.v2.color.primary,
      locationColor: theme.v2.color.typography.bodyText1,
      hoverBackgroundColor: theme.v2.color.secondary,
      hoverTextColor: theme.v2.color.background,
      borderRadius: theme.v2.decoration?.border?.radius?.default || "0px",
      selectedColor: theme.v2.color.background,
    };
  }

  getCategoryBarProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.categoryBar)};
    const categoryButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryButton")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.base.grey["100"],
        columnGap: {
          lg: "48px",
          md: "48px",
          sm: "48px",
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v2.typography.title4.family,
          color: theme.v2.color.onSurface,
          selectedBorder: theme.v2.color.primary,
          fontWeight: categoryButtonTheme.v2.typography.title4.weight,
          lineHeight: categoryButtonTheme.v2.typography.title4.lineHeight,
          selectedColor: categoryButtonTheme.v2.color.onBackground,
          textTransform: categoryButtonTheme.v2.typography.title4.textCase,

          fontSize: {
            lg: categoryButtonTheme.v2.typography.title4.size.lg,
            md: categoryButtonTheme.v2.typography.title4.size.md,
            sm: categoryButtonTheme.v2.typography.title4.size.md,
          },
        },
      },
    };
  }

  getProductCardProps(_theme, props = {}, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const buyTextTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.buyText")),
    };
    const primaryButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.primaryButton")),
    };

    const strikePriceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.crossed")),
    };

    const soloBuyTextTheme = {
      v2: get(theme, "v2.overrides.productCard.buyText"),
    };
    const soloRootTheme = {v2: get(theme, "v2.overrides.productCard.card")};
    const height = {
      lg: site.getUiConfiguration().productCard.height?.lg || "510px",
      md: site.getUiConfiguration().productCard.height?.md || "510px",
      sm: site.getUiConfiguration().productCard.height?.sm || "510px",
    };

    const imageHeight = {
      lg: site.getUiConfiguration().productCard.imageHeight?.lg || "290px",
      md: site.getUiConfiguration().productCard.imageHeight?.md || "290px",
      sm: site.getUiConfiguration().productCard.imageHeight?.sm || "290px",
    };

    const getMinWidthSM = () => {
      if (props.isHorizontalInMobile) return "300px";
      if (props.mode === "small") return "100%";

      return "200px";
    };

    const getHeightSM = () => {
      if (props.isHorizontalInMobile) return "220px";
      if (props.mode === "small") return "270px";

      return height.sm;
    };

    const getImageHeightSM = () => {
      if (props.isHorizontalInMobile) return "155px";
      if (props.mode === "small") return "150px";

      return imageHeight.sm;
    };

    return {
      root: {
        ...this.getCardProps(theme, soloRootTheme).root,
        height: {
          lg: props.mode === "small" ? "270px" : height.lg,
          md: props.mode === "small" ? "270px" : height.md,
          sm: getHeightSM(),
        },
        maxWidth: {
          lg: "395px",
          md: "100%",
          sm: props.isHorizontalInMobile ? "368px" : "100%",
        },
        minWidth: {
          lg: "0",
          md: "150px",
          sm: getMinWidthSM(),
        },
      },
      image: {
        borderBottom: "0",
        borderRadius: "0",
        height: {
          lg: props.mode === "small" ? "150px" : imageHeight.lg,
          md: props.mode === "small" ? "150px" : imageHeight.md,
          sm: getImageHeightSM(),
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: "0",
          md: "0",
          sm: "0",
        },
      },
      flowerTypeIndicator: {
        position: {
          bottom: "",
          left: "",
          right: "0",
          top: props.mode === "small" ? "8px" : "0",
        },
        fontSize: {
          lg: flowerTypeIndicatorTheme.v2.typography.title6.size.lg,
          md: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
          sm: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
        },
        padding: props.isHorizontalInMobile
          ? "4px"
          : props.mode === "small"
          ? "5px"
          : "10px",
        margin: site.getUiConfiguration().productCard.flowerTypeIndicator.margin,
        borderRadius: flowerTypeIndicatorTheme.v2.decoration.border.radius.xxs,
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        textTransform: flowerTypeIndicatorTheme.v2.typography.title6.textCase,
      },
      info: {
        padding: {
          lg: "8px 8px 24px",
          md: "8px 8px 24px",
          sm: props.isHorizontalInMobile ? "8px 0 0" : "8px",
        },
      },
      brandName: {
        color: brandNameTheme.v2.color.base.grey["500"],
        fontFamily: brandNameTheme.v2.typography.bodyText1.family,
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText1.size.lg,
          md: brandNameTheme.v2.typography.bodyText1.size.lg,
          sm: brandNameTheme.v2.typography.bodyText1.size.lg,
        },
        fontWeight: brandNameTheme.v2.typography.bodyText1.weight,
        textTransform: brandNameTheme.v2.typography.bodyText1.textCase,
      },
      productName: {
        color: productNameTheme.v2.color.onSurface,
        fontFamily: productNameTheme.v2.typography.title6.family,
        fontSize: {
          lg: productNameTheme.v2.typography.title6.size.lg,
          md: productNameTheme.v2.typography.title6.size.lg,
          sm: productNameTheme.v2.typography.title6.size.lg,
        },
        fontWeight: productNameTheme.v2.typography.title6.weight,
      },
      saleIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        borderRadius: theme.v2.decoration.border.radius.xxs,
        fontSize: {
          lg: flowerTypeIndicatorTheme.v2.typography.title6.size.lg,
          md: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
          sm: flowerTypeIndicatorTheme.v2.typography.title6.size.sm,
        },
        fontFamily: flowerTypeIndicatorTheme.v2.typography.title6.family,
        top: props.mode === "small" ? "0px" : "5px",
        margin: {
          lg:
            props.mode === "small"
              ? "8px 0"
              : site.getUiConfiguration().productCard.saleIndicator.margin,
          md:
            props.mode === "small"
              ? "8px 0"
              : site.getUiConfiguration().productCard.saleIndicator.margin,
          sm:
            props.mode === "small"
              ? "8px 0"
              : site.getUiConfiguration().productCard.saleIndicator.margin,
        },
      },
      productSizes: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: theme.v2.typography.bodyText2.size,
        fontWeight: theme.v2.typography.bodyText2.weight,
        color: theme.v2.color.base.grey["600"],
      },
      potencyTag: {
        fontFamily: theme.v2.typography.bodyText2.family,
        fontSize: {
          lg: theme.v2.typography.bodyText2.size.lg,
          md: theme.v2.typography.bodyText2.size.md,
          sm: theme.v2.typography.bodyText2.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText2.weight,
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
        padding: {
          md: "4px",
        },
        border: 0,
        borderRadius: theme.v2.decoration.border.radius.xxs,
        height: "20px",
      },
      actionsContainer: {
        padding: {
          lg: "0 8px 8px 8px",
          md: "0 8px 8px 8px",
          sm: props.isHorizontalInMobile
            ? "10px"
            : site.getUiConfiguration().productCard.actionsContainer.padding,
        },
      },
      actions: {
        actions: {
          borderRadius: theme.v2.decoration.border.radius.xs,
        },
        root: {
          cartColor: actionsTheme.v2.color.onSecondary,
          backgroundColor: actionsTheme.v2.color.primary,
          color: actionsTheme.v2.color.onBackground,
          borderRadius: theme.v2.decoration.border.radius.xs,
        },
        addToCartButton: {
          position: site.getUiConfiguration().addToCartButton.position,
        },
        buyText: {
          fontSize: {
            lg: buyTextTheme.v2.typography.title5.size.lg,
            md: buyTextTheme.v2.typography.title5.size.md,
            sm: buyTextTheme.v2.typography.title5.size.sm,
          },
          fontWeight: buyTextTheme.v2.typography.title5.weight,
          borderRadius: buyTextTheme.v2.decoration.border.radius.xs,
          borderColor: buyTextTheme.v2.color.primary,
          color: soloBuyTextTheme.v2?.color?.onBackground,
          borderWidth: buyTextTheme.v2.decoration.border.width.s,
          padding:
            site.getUiConfiguration().addToCartButton.position === "right" ? "17px" : "",
          textTransform: primaryButtonTheme.v2.typography.bodyText1.textCase,
        },
        prices: {
          position: {
            lg: "right",
            md: "right",
            sm: "left",
          },
          crossed: {
            fontSize: {
              lg: strikePriceTheme.v2.typography.bodyText2.size.lg,
              md: strikePriceTheme.v2.typography.bodyText2.size.lg,
              sm: strikePriceTheme.v2.typography.bodyText2.size.lg,
            },
            fontWeight: actionsTheme.v2.typography.bodyText2.weight,
            color:
              props.mode === "small"
                ? actionsTheme.v2.color.primary
                : actionsTheme.v2.color.background,
          },
          alignment: "column-reverse",
          side: "flex-end",
          fontSize: {
            lg: actionsTheme.v2.typography.title5.size.lg,
            md: actionsTheme.v2.typography.title5.size.lg,
            sm: actionsTheme.v2.typography.title5.size.md,
          },
          fontWeight: actionsTheme.v2.typography.title5.weight,
          color: props.isHorizontalInMobile
            ? actionsTheme.v2.color.primary
            : props.mode === "small"
            ? actionsTheme.v2.color.primary
            : actionsTheme.v2.color.background,
        },
      },
    };
  }

  getProductDetailProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.productDetail"))};
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.secondaryTags")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const quantityPickerTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.quantityPicker")),
    };
    const addToCartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.addToCartButton")),
    };
    const primaryButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.primaryButton")),
    };
    const addToCartButtonBorderTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.addToCartButton.border")
      ),
    };
    const addToCartButtonThemeOnly = {
      v2: get(theme, "v2.overrides.productDetail.addToCartButton"),
    };

    const PriceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    return {
      brand: {
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.title6.size.lg"),
          md: get(brandNameTheme, "v2.typography.title6.size.lg"),
          sm: get(brandNameTheme, "v2.typography.title6.size.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.title6.weight"),
        color: get(brandNameTheme, "v2.color.base.grey.500"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
        fontFamily: get(brandNameTheme, "v2.typography.title6.family"),
      },
      name: {
        fontSize: get(theme, "v2.typography.title3.size"),
        fontWeight: get(theme, "v2.typography.title3.weight"),
        fontFamily: get(theme, "v2.typography.title3.family"),
        color: get(theme, "v2.color.onSurface"),
      },
      mainTagsContainer: {
        columnGap: "10px",
        margin: "0",
      },
      flowerTypeIndicator: {
        padding: `${get(theme, "v2.static.spacing.size.xs")} ${get(
          theme,
          "v2.static.spacing.size.s"
        )}`,
        border: "none",
        borderRadius: theme.v2.decoration.border.radius.xxs,
        textTransform: get(theme, "v2.text.case.capitalize"),
        fontSize: {
          lg: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          md: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.weight"),
        fontFamily: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.family"),
      },
      mainTags: {
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
        border: "none",
        borderRadius: theme.v2.decoration.border.radius.xxs,
        backgroundColor: get(theme, "v2.color.base.grey.200"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
          md: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(potencyTagTheme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText1.weight"),
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText1.family"),
        color: get(potencyTagTheme, "v2.color.onSecondary"),
      },
      description: {
        root: {
          margin: "0",
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            md: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight"),
          color: get(descriptionTheme, "v2.color.onSurface"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.title6.size.lg"),
            md: get(descriptionTheme, "v2.typography.title6.size.lg"),
            sm: get(descriptionTheme, "v2.typography.title6.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.title6.weight"),
          color: get(descriptionTheme, "v2.color.onSurface"),
        },
      },
      priceDisplay: {
        price: {
          fontSize: {
            lg: get(theme, "v2.typography.title5.size.lg"),
            md: get(theme, "v2.typography.title5.size.lg"),
            sm: get(theme, "v2.typography.title5.size.lg"),
          },
          fontWeight: get(theme, "v2.typography.title5.weight"),
          color: get(theme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(PriceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(PriceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(PriceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(PriceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(theme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(theme, "v2.typography.caption1.size.lg"),
            md: get(theme, "v2.typography.caption1.size.lg"),
            sm: get(theme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(theme, "v2.typography.caption1.weight"),
          color: get(theme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 ${get(quantityPickerTheme, "v2.static.spacing.size.l")}`,
          },
        },
        quantityPicker: {
          borderRadius: get(quantityPickerTheme, "v2.decoration.border.radius.xs"),
          backgroundColor: get(quantityPickerTheme, "v2.color.base.grey.100"),
          quantityColor: get(quantityPickerTheme, "v2.color.onBackground"),
          controllersColor: get(quantityPickerTheme, "v2.color.onPrimary"),
          quantitySize: get(quantityPickerTheme, "v2.typography.title4.size"),
          quantityWeight: get(quantityPickerTheme, "v2.typography.title4.weight"),
          controllersWidth: {
            lg: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            md: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          },
          controllersHeight: {
            lg: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            md: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
            sm: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          },
          minusIconSize: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          plusIconSize: get(quantityPickerTheme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: get(quantityPickerTheme, "v2.color.onPrimary"),
          },
        },
        addToCartButton: {
          backgroundColor: get(addToCartButtonTheme, "v2.color.primary"),
          color: get(addToCartButtonTheme, "v2.color.onPrimary"),
          borderRadius: get(addToCartButtonTheme, "v2.decoration.border.radius.xs"),
          fontSize: get(addToCartButtonTheme, "v2.typography.title4.size"),
          fontWeight: get(addToCartButtonTheme, "v2.typography.title4.weight"),
          borderColor: get(addToCartButtonBorderTheme, "v2.color.onPrimary"),
          borderWidth:
            addToCartButtonThemeOnly.v2?.decoration?.border?.width?.default || 0,
          textTransform: get(primaryButtonTheme, "v2.typography.bodyText1.textCase"),
        },
      },
      secondaryTags: {
        backgroundColor: get(secondaryTagsTheme, "v2.color.secondary"),
        color: get(secondaryTagsTheme, "v2.color.onSecondary"),
        borderRadius: theme.v2.decoration.border.radius.xxs,
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.bodyText1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.bodyText1.size.lg"),
        },
        fontWeight: get(secondaryTagsTheme, "v2.typography.bodyText1.weight"),
        textTransform: get(theme, "v2.text.case.capitalize"),
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productList.title")),
    };

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
          sm: 1,
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onBackground,
          fontWeight: titleTheme.v2.typography.title1.weight,
          textTransform: titleTheme.v2.typography.title1.textCase,
          fontSize: {
            lg: titleTheme.v2.typography.title1.size.lg,
            md: titleTheme.v2.typography.title1.size.md,
            sm: titleTheme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.title5.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.title5.size.lg,
              md: descriptionTheme.v2.typography.title5.size.lg,
              sm: descriptionTheme.v2.typography.title5.size.lg,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.title1.weight,
            color: descriptionTheme.v2.color.onBackground,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.title5.weight,
            color: descriptionTheme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getShowcasedProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.showcasedProductsList.title")),
    };

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 16px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
        },
      },
      element: {
        maxWidth: {
          lg: site.getUiConfiguration().showcasedProductsList.element.maxWidth.lg,
          md: site.getUiConfiguration().showcasedProductsList.element.maxWidth.md,
          sm: site.getUiConfiguration().showcasedProductsList.element.maxWidth.sm,
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "100%",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.onBackground,
          fontWeight: titleTheme.v2.typography.title1.weight,
          textTransform: titleTheme.v2.typography.title1.textCase,
          fontSize: {
            lg: titleTheme.v2.typography.title1.size.lg,
            md: titleTheme.v2.typography.title1.size.md,
            sm: titleTheme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        viewAll: {
          position: site.getUiConfiguration().showcasedProductsList.viewAll.position,
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.title5.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.title5.size.lg,
              md: descriptionTheme.v2.typography.title5.size.md,
              sm: descriptionTheme.v2.typography.title5.size.md,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.title5.lineHeight,
              md: descriptionTheme.v2.typography.title5.lineHeight,
              sm: descriptionTheme.v2.typography.title5.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.title1.weight,
            color: descriptionTheme.v2.color.onBackground,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.title5.weight,
            color: descriptionTheme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "45px",
          backgroundColor: theme.v2.color.secondary,
          color: theme.v2.color.onBackground,
        },
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onBackground,
      },
      closeIcon: {
        color: theme.v2.color.onBackground,
      },
    };
  }

  getSortFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const sortTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.sort")),
    };

    const getFiltersBorderRadius = () => {
      const backgroundColor = theme.v2.overrides.tertiaryButton?.color?.background;
      const invisibleColors = ["#fff", "#ffffff", "white", "transparent"];
      const borderRadius =
        theme.v2.overrides.tertiaryButton?.decoration?.border?.radius?.xs;

      if (!backgroundColor || invisibleColors.includes(backgroundColor))
        return `calc(${borderRadius || "0px"} + 3px)`;

      return borderRadius || "0px";
    };
    return {
      root: {
        padding: {
          lg: `0 0 ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`,
          md: `0 0 ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`,
        },
      },
      sort: {
        root: {
          width: {
            lg: "230px",
            sm: "190px",
          },
          control: {
            borderColor: theme.v2.color.onBackground,
            borderRadius: getFiltersBorderRadius(),
            borderWidth: {
              lg: "3px",
              md: "3px",
              sm: "0",
            },
            color: theme.v2.overrides.tertiaryButton?.color?.onBackground,
            backgroundColor: theme.v2.overrides.tertiaryButton?.color?.background,
            fontFamily: sortTheme.v2.typography.title4.family,
            fontSize: {
              lg: sortTheme.v2.typography.title4.size.lg,
              md: sortTheme.v2.typography.title4.size.lg,
              sm: sortTheme.v2.typography.title4.size.lg,
            },
            fontWeight: sortTheme.v2.typography.title4.weight,
            lineHeight: sortTheme.v2.typography.title4.lineHeight,
            hover: {
              borderColor: sortTheme.v2.color.background,
            },
            height: {
              lg: "50px",
              sm: "56px",
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.xxs,
            backgroundColor: theme.v2.overrides?.tertiaryButton?.color.background,
            borderColor: sortTheme.v2.color.onBackground,
          },
          options: {
            color: theme.v2.overrides.tertiaryButton?.color?.onBackground,
            fontFamily: sortTheme.v2.typography.title4.family,
            fontSize: {
              lg: sortTheme.v2.typography.title4.size.lg,
              sm: sortTheme.v2.typography.title4.size.md,
            },
            fontWeight: sortTheme.v2.typography.title4.weight,
            hover: {
              backgroundColor: theme.v2.overrides.tertiaryButton?.color?.onBackground,
              color: theme.v2.overrides.tertiaryButton?.color?.background,
            },
            selected: {
              backgroundColor: theme.v2.overrides.tertiaryButton?.color?.onBackground,
              color: theme.v2.overrides.tertiaryButton?.color?.background,
            },
          },
          arrow: {
            borderColor: `${theme.v2.overrides.tertiaryButton?.color?.onBackground} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${theme.v2.overrides.tertiaryButton?.color?.onBackground}`,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: sortTheme.v2.typography.title4.family,
            fontSize: sortTheme.v2.typography.title4.size.lg,
            borderRadius: getFiltersBorderRadius(),
            fontWeight: sortTheme.v2.typography.title4.weight,
            hoverBorderColor: theme.v2.color.background,
            border: {
              lg: `3px solid ${theme.v2.color.background}`,
              md: "none",
              sm: "none",
            },
            boxShadow: {
              sm: "0 0 20px -5px rgba(0, 0, 0, 0.2)",
            },
          },
          large: {
            height: "56px",
          },
          secondary: {
            backgroundColor: theme.v2.overrides.tertiaryButton?.color?.background,
            color: theme.v2.overrides.tertiaryButton?.color?.onBackground,
          },
        },
      },
    };
  }

  getFooterProps(theme) {
    return {
      element: {
        hoverLinkColor: theme.v2.color.primary,
        activeLinkColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.title6.family,
      },
      root: {
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.md,
        },
      },
    };
  }

  getCardProps(theme, rootTheme) {
    const rootBorder = `${rootTheme.v2?.decoration?.border?.width?.default || 0} solid ${
      rootTheme.v2?.color?.border || "transparent"
    }`;

    return {
      root: {
        border: `${rootTheme.v2?.decoration?.border?.width?.default} solid ${theme.v2.color.background}`,
        hoverBorder: rootBorder,
        borderRadius: rootTheme.v2?.decoration?.border?.radius?.default || "none",
        boxShadow: theme.v2.decoration.shadow.default
          ? applyShadow(theme.v2.decoration.shadow.default)
          : "none",
      },
    };
  }

  getDealsListProps(theme) {
    return this.defaultThemeProps.getDealsListProps(theme);
  }

  getDealDetailProps(theme) {
    return this.defaultThemeProps.getDealDetailProps(theme);
  }

  getDealCardProps(theme) {
    const rootTheme = {v2: merge({}, theme.v2, theme.v2.overrides.dealCard)};
    return {
      ...this.defaultThemeProps.getDealCardProps(theme),
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "250px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      type: {
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.lg,
          sm: theme.v2.typography.bodyText1.size.lg,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.base.grey["500"],
      },
      title: {
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.lg,
        },
        fontWeight: theme.v2.typography.title6.weight,
        color: theme.v2.color.onSurface,
        fontFamily: theme.v2.typography.title6.family,
      },
    };
  }

  getRecommendedListProps(theme) {
    return {
      list: {
        justifyContent: "flex-start",
      },
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
      },
      header: {},
      indicator: {
        indicator: {
          color: theme.v2.color.primaryVariant,
        },
      },
      navigationButton: {
        root: {},
      },
    };
  }

  getTitleProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.title"))};
    return {
      fontFamily: theme.v2.typography.title1.family,
      fontSize: {
        sm: theme.v2.typography.title1.size.md,
        md: theme.v2.typography.title1.size.lg,
        lg: theme.v2.typography.title1.size.lg,
      },
      letterSpacing: {
        sm: theme.v2.typography.title1.letterSpacing,
        md: theme.v2.typography.title1.letterSpacing,
        lg: theme.v2.typography.title1.letterSpacing,
      },
      fontWeight: theme.v2.typography.primary.weight.heavy,
      color: theme.v2.color.onBackground,
      margin: 0,
      padding: 0,
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "> span": ` {
        cursor: default;
        font-family: ${theme.v2.typography.body.family};
    
        &:hover {
          text-decoration: none;
        }
      }`,
    };
  }

  getProductRecommendationsProps() {
    return {
      element: {
        minWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
      },
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.xs,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary,
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.xs,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0,
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
        groupedProductsList: {
          elementsPerRow: {
            lg: 4,
            md: 3,
            sm: 1,
          },
        },
      },
    };
  }

  getSubcategoriesProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: theme.v2.typography.bodyText1.size,
        fontWeight: theme.v2.typography.bodyText1.weight,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      selected: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      unselected: {
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
      },
    };
  }
}

memoizeClassMethods(SenseThemeProps);

export default SenseThemeProps;
