import ProductsPage from "@themes/boost/pages/ProductsPage";
import PromotionalBanner from "@themes/boost/components/PromotionalBanner";
import ShowcaseDealsList from "@themes/boost/components/ShowcaseDealsList";
import defaultConfig from "@themes/default/config";

const config = {
  inherits: "default",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 10,
  },
  components: {
    /*ActionsSortFilter: ActionsSortFilter,
          AppliedFilters: AppliedFilters,
          BrandHeader: BrandHeader,
          Button: Button,
          CategorizedProductsBrowser: CategorizedProductsBrowser,
          CategoryBar: CategoryBar,
          DeliveryBanner: DeliveryBanner,
          Footer: Footer,
          Header: Header,
          Modal: Modal,
          ProductCard: ProductCard,
          ProductDetail: ProductDetail,
          ProductsList: ProductsList,
          RecommendedProductsList: RecommendedProductsList,
          ShowCasedProductsList: ShowCaseProductsList,
          ShowCaseSection: ShowCaseSection,*/
    PromotionalBanner: PromotionalBanner,
    ShowcaseDealsList: ShowcaseDealsList,
    // Title: Title,
    // ViewAllLink: ViewAllLink,
  },
  pages: {
    HomePage: ProductsPage,
    // ProductsPage: ProductsPage,
    // AllCategoriesPage: ProductsPage,
    // BrandPage: BrandPage,
    // ProductDetailPage: ProductsPage,
    // DealsPage: DealsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};

export const components = {
  ...defaultConfig.components,
  ...defaultConfig.pages,

  ...config.components,
  ...config.pages,
};

export default config;
