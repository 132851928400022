import styled from "styled-components";
import media from "@ui/utils/media";
import {Button} from "@ui";
import {BackIcon} from "@ui/components/Icons";
import React from "react";

export default function BackButton({label = "Back", onClick}) {
  return (
    <ButtonContainer>
      <Button
        styles={styles}
        label={label}
        variant="secondary"
        LeftIconComponent={BackIcon}
        onClick={onClick}
      />
    </ButtonContainer>
  );
}

const styles = {
  root: {
    border: "0px",
  },
  small: {
    padding: 0,
    height: "35px",
  },
  medium: {
    padding: 0,
    height: "45px",
  },
  large: {
    padding: 0,
    height: "55px",
  },
};

const ButtonContainer = styled.div`
  padding: 16px 32px;

  ${media.down("md")} {
    padding: 16px 16px;
  }
`;
