import BaseThemeButton from "src/themes/baseTheme/elements/buttons/Button";
import BaseThemeShowCaseProductsList from "src/themes/baseTheme/components/ShowCaseProductsList";
import BaseThemeProductsList from "src/themes/baseTheme/components/ProductsList";
import BaseThemeCategorizedProductsBrowser from "src/themes/baseTheme/components/CategorizedProductsBrowser";
import BaseThemeProductDetail from "src/themes/baseTheme/components/ProductDetail";
import BaseThemeDeliveryBanner from "src/themes/baseTheme/components/DeliveryBanner";
import BaseThemeBrandPage from "src/themes/baseTheme/pages/BrandPage";
import BaseThemeProductsPage from "src/themes/baseTheme/pages/ProductsPage";
import BaseThemeProductCard from "src/themes/baseTheme/components/ProductCard";
import BaseThemeViewAllLink from "src/themes/baseTheme/elements/ViewAllLink";
import BaseThemeBrandHeader from "src/themes/baseTheme/elements/BrandHeader";
import BaseThemeFooter from "src/themes/baseTheme/components/Footer";
import BaseThemeShowCaseSection from "src/themes/baseTheme/elements/ShowCaseSection";
import BasethemeShowcaseDealsList from "src/themes/baseTheme/components/ShowcaseDealsList";
import BaseThemeHeader from "src/themes/baseTheme/components/Header";
import BaseThemePromotionalBanner from "src/themes/baseTheme/components/PromotionalBanner";
import BaseThemeRecommendedProductsList from "src/themes/baseTheme/elements/RecommendedProductsList";
import BaseThemeCategoryBar from "src/themes/baseTheme/components/CategoryBar";
import {BaseThemeTitle} from "src/themes/baseTheme/elements/Title";
import BaseThemeActionsSortFilter from "src/themes/baseTheme/elements/filters/ActionsSortFilter";
import BaseThemeAppliedFiltersSection from "src/themes/baseTheme/elements/filters/AppliedFiltersSection";
import BaseThemeProductsOrderSelector from "src/themes/baseTheme/elements/ProductsOrderSelector";
import BaseThemeBasePage from "src/themes/baseTheme/pages/BasePage";
import suspensify from "@ui/utils/suspensify";
import {lazy} from "react";

const BaseThemeModal = suspensify(
  lazy(() =>
    import("src/themes/baseTheme/elements/Modal").then(module => ({
      default: module.BaseThemeModal,
    }))
  )
);

export default {
  inherits: "default",
  vars: {
    SHOWCASED_PREFETCH_LIMIT: 10,
  },
  components: {
    Button: BaseThemeButton,
    Header: BaseThemeHeader,
    Footer: BaseThemeFooter,
    Modal: BaseThemeModal,
    RecommendedProductsList: BaseThemeRecommendedProductsList,
    ShowCasedProductsList: BaseThemeShowCaseProductsList,
    ProductsList: BaseThemeProductsList,
    CategorizedProductsBrowser: BaseThemeCategorizedProductsBrowser,
    ProductDetail: BaseThemeProductDetail,
    CategoryBar: BaseThemeCategoryBar,
    DeliveryBanner: BaseThemeDeliveryBanner,
    Title: BaseThemeTitle,
    ProductCard: BaseThemeProductCard,
    ViewAllLink: BaseThemeViewAllLink,
    BrandHeader: BaseThemeBrandHeader,
    ShowCaseSection: BaseThemeShowCaseSection,
    PromotionalBanner: BaseThemePromotionalBanner,
    ShowcaseDealsList: BasethemeShowcaseDealsList,
    ActionsSortFilter: BaseThemeActionsSortFilter,
    AppliedFiltersSection: BaseThemeAppliedFiltersSection,
    ProductsOrderSelector: BaseThemeProductsOrderSelector,
  },
  pages: {
    BrandPage: BaseThemeBrandPage,
    BasePage: BaseThemeBasePage,
    ProductsPage: BaseThemeProductsPage,
    ProductDetailPage: BaseThemeProductsPage,
    HomePage: BaseThemeProductsPage,
    AllCategoriesPage: BaseThemeProductsPage,
    SubcategoryPage: BaseThemeProductsPage,
  },
  options: {
    pages: {
      productDetail: {
        isModal: true,
      },
    },
  },
};
