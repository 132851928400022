import makeBreadcrumbsProductDetailSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductDetail";
import makeProductDetailSchema from "src/core/seo/schema-objects/factories/productDetail";
import makeSiteDetailSchema from "src/core/seo/schema-objects/factories/siteDetail";
import useSite from "src/core/sites/hooks/useSite";
import {trackProductDetailView} from "src/core/analytics/ecommerce/events";
import {useEffect} from "react";
import useRouter from "src/core/common/hooks/useRouter";
import routes from "src/core/common/routes";
import useQuantityPicker from "src/core/checkout/hooks/useQuantityPicker";
import useProductInfo from "src/menu/hooks/useProductInfo";
import {useProductDetailResource} from "src/state/hooks/useAsyncResource";
import ProductDetailSchema from "src/core/seo/components/ProductDetailSchema";
import {PageHead} from "src/core/seo/components/Head";
import EmptyPlaceholderImage from "@ui/components/EmptyPlaceholder/assets/empty-placeholder.png";
import ProductDetailHeaderActions from "src/menu/components/ProductDetailHeaderActions";
import ProductDetailActions from "src/menu/components/ProductDetailActions";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";

function useProductDetail({productId: pId, onAddItem}, opts = {}) {
  const site = useSite();
  const router = useRouter();
  const {productId: routingProductId, noBrandProductId} = router.query;
  const productId = pId || routingProductId || noBrandProductId;
  const toggles = useFeatureToggles();

  const {data: product, meta} = usePrefetchedProduct(productId);

  const schemas = product
    ? [
        makeProductDetailSchema(product, site),
        makeSiteDetailSchema(product, site),
        makeBreadcrumbsProductDetailSchema(product),
      ].map(schema => ({
        type: "application/ld+json",
        innerHTML: JSON.stringify(schema),
      }))
    : [];

  const onEmptyPlaceholderButtonClickHandler = () => {
    router.push(routes.home);
  };

  const quantityPickerProps = useQuantityPicker({
    product,
    onAddItem,
    priceDisplay: opts.priceDisplay,
  });

  const productInfoProps = useProductInfo({
    product,
    quantity: quantityPickerProps.quantity,
    weight: quantityPickerProps.weight,
    priceDisplay: opts.priceDisplay,
  });

  useEffect(() => {
    product && trackProductDetailView(product);
  }, [product]);

  return {
    site,
    openModal: Boolean(productId),
    product,
    meta,
    schemas,
    onEmptyPlaceholderButtonClickHandler,
    hasError: !product && meta.error && meta.error.status === 404,
    showSkeleton: !meta.error && !product,
    quantityPickerProps,
    productInfoProps,
    ProductDetailSchema,
    PageHead,
    EmptyPlaceholderImage,
    ProductDetailHeaderActions,
    ProductDetailActions,
    showProductDetailRecommendations: toggles.showProductDetailRecommendations(),
  };
}

function usePrefetchedProduct(productId) {
  const {data} = useServerContext();

  const prefetchedProduct =
    productId && data[PrefetchedDataKeys.PRODUCT]?.getSlug?.() === productId
      ? data[PrefetchedDataKeys.PRODUCT]
      : null;

  const {data: fetchedProduct, meta} = useProductDetailResource({
    productId: prefetchedProduct ? null : productId,
  });

  if (prefetchedProduct) {
    return {
      data: prefetchedProduct,
      meta: {
        error: null,
        loading: false,
      },
    };
  }

  return {data: fetchedProduct, meta: meta};
}

export default useProductDetail;
