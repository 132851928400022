import merge from "lodash/merge";
import get from "lodash/get";
import applyShadow from "@ui/utils/applyShadow";
import memoizeClassMethods from "@ui/utils/memoizeClassMethods";
import DefaultThemeProps from "@themes/default/themeStyles/defaultThemeProps";

export default class BoostThemeProps {
  static cache = {};

  constructor() {
    this.defaultThemeProps = new DefaultThemeProps();
  }

  getModalProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.modal"))};

    return {
      content: {
        borderRadius: {
          lg: theme.v2.decoration.border.radius.default,
          md: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
          sm: `${theme.v2.decoration.border.radius.default} ${theme.v2.decoration.border.radius.default} 0 0`,
        },
      },
      overlay: {
        color: theme.v2.color.primary,
      },
    };
  }

  getHeaderProps(theme) {
    return {
      root: {
        backgroundColor: theme.v2.color.surface,
        borderBottomColor: theme.v2.color.background,
        borderWidth: theme.v2.decoration.border.width.xs,
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onSurface,
        fontWeight: theme.v2.typography.bodyText1.weight,
        fontSize: theme.v2.typography.bodyText1.size,
        letterSpacing: theme.v2.typography.bodyText1.letterSpacing,
        textTransform: theme.v2.typography.bodyText1.textCase,
      },
      logo: {
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "120px",
        },
      },
    };
  }

  getHeaderLoginButtonProps(theme, options = {}) {
    return {
      outlineColor: theme.v2.color.secondary,
      borderRadius: theme.v2.decoration.border.radius.default,
      padding: options.loginText !== "login" ? "10px" : "8px 24px", // only used if outline/highlight is set to true
      highlightColor: theme.v2.color.primary,
    };
  }

  getHeaderSearchButtonProps(theme) {
    const searchButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.searchButton")),
    };

    return {
      borderRadius: searchButtonTheme.v2.decoration.border.radius.default,
      outlineColor: searchButtonTheme.v2.color.secondary,
      color: searchButtonTheme.v2.color.onSurface,
      highlightColor: searchButtonTheme.v2.color.primary,
    };
  }

  getHeaderCartButtonProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartButton")),
    };

    return {
      borderRadius: cartButtonTheme.v2.decoration.border.radius.default,
      highlightColor: cartButtonTheme.v2.color.primary,
      highlightTextColor: cartButtonTheme.v2.color.onPrimary,
      color: cartButtonTheme.v2.color.surface,
    };
  }

  getHeaderCartBadgeProps(theme) {
    const cartButtonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.cartButtonBadge")),
    };
    return {
      color: cartButtonTheme.v2.color.surface,
    };
  }

  getDeliveryBannerProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.deliveryBanner"))};
    const themeCounter = {
      v2: merge(
        {},
        theme.v2,
        get(_theme.v2, "overrides.deliveryBanner.deliveryModePicker.productsCounter")
      ),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
        fontFamily: theme.v2.typography.title6.family,
        color: theme.v2.color.onSurface,
        fontWeight: theme.v2.typography.title6.weight,
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.md,
        },
        locationText: {
          fontFamily: theme.v2.typography.bodyText2.family,
          fontWeight: theme.v2.typography.bodyText2.weight,
          fontSize: {
            lg: theme.v2.typography.bodyText2.size.lg,
            md: theme.v2.typography.bodyText2.size.lg,
            sm: theme.v2.typography.bodyText2.size.md,
          },
        },
      },
      productsCounter: {
        selectedColor: themeCounter.v2.color.onSurface,
      },
    };
  }

  getDeliveryModePickerProps(_theme, showInventoryPicker) {
    const theme = {
      v2: merge(
        {},
        _theme.v2,
        get(_theme.v2, "overrides.deliveryBanner.deliveryModePicker")
      ),
    };

    return {
      backgroundColor: theme.v2.color.background,
      color: theme.v2.color.typography.title6,
      hoverSelectedBackgroundColor: theme.v2.color.primaryVariant,
      pickupColor: theme.v2.color.primary,
      locationColor: theme.v2.color.typography.bodyText1,
      hoverBackgroundColor: theme.v2.color.primary,
      hoverTextColor: theme.v2.color.surface,
      selectedColor: theme.v2.color.surface,
      borderRadius: theme.v2.decoration.border.radius.default,
    };
  }

  getSelectionBarProps(_theme) {
    return this.defaultThemeProps.getSelectionBarProps(_theme);
  }

  getCategoryBarProps(_theme, options = {}, site) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.categoryBar"))};
    const categoryButtonTheme = {
      v2: merge(
        {
          spacing: {
            size: {
              xs: "8px",
              m: "16px",
            },
          },
        },
        theme.v2,
        get(theme, "v2.overrides.categoryBar.categoryButton")
      ),
    };
    const categoryImageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.categoryBar.categoryImage")),
    };

    return {
      root: {
        scroll: {
          lg: site.getUiConfiguration().categoryBar?.scroll.lg,
          md: site.getUiConfiguration().categoryBar?.scroll.md,
          sm: site.getUiConfiguration().categoryBar?.scroll.sm,
        },
        backgroundColor: theme.v2.color.secondary,
        minHeight: {
          lg: "70px",
          md: "50px",
          sm: "50px",
        },
        height: {
          lg:
            site.getUiConfiguration().categoryBar?.scroll.lg === true ? "160px" : "70px",
          md:
            site.getUiConfiguration().categoryBar?.scroll.lg === true ? "160px" : "70px",
          sm: "70px",
        },
        columnGap: {
          lg: theme.v2.spacing("m"),
          md: theme.v2.spacing("xs"),
          sm: theme.v2.spacing("xs"),
        },
        padding: {
          lg: `${theme.v2.spacing("xs")} 0`,
          md: `${theme.v2.spacing("xs")} 0`,
          sm: `${theme.v2.spacing("xs")} 0`,
        },
      },
      button: {
        root: {
          fontFamily: categoryButtonTheme.v2.typography.title5.family,
          backgroundColor: categoryButtonTheme.v2.color.surface,
          color: categoryButtonTheme.v2.color.onSurface,
          selectedColor: categoryButtonTheme.v2.color.background,
          selectedBackgroundColor: categoryButtonTheme.v2.color.primary,
          fontWeight: categoryButtonTheme.v2.typography.title5.weight,
          padding: {
            lg: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
            md: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
            sm: `${categoryButtonTheme.v2.spacing("xs")} ${categoryButtonTheme.v2.spacing(
              "xl"
            )}`,
          },
          fontSize: {
            lg: categoryButtonTheme.v2.typography.title5.size.lg,
            md: categoryButtonTheme.v2.typography.title5.size.md,
            sm: categoryButtonTheme.v2.typography.title5.size.sm,
          },
          textTransform: categoryButtonTheme.v2.typography.title5.textCase,
          border: "none",
          borderRadius: categoryButtonTheme.v2.decoration.border.radius.default,
          height: {
            lg: "56px",
            md: "48px",
            sm: "48px",
          },
          boxShadow: options.buttonShadow
            ? applyShadow(get(categoryButtonTheme, "v2.decoration.shadow.s", []))
            : "none",
        },
        image: {
          size: {
            lg: "44px",
            md: "36px",
            sm: "36px",
          },
          borderRadius: categoryImageTheme.v2.decoration.border.radius.default,
        },
      },
    };
  }

  getDealsListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.dealsList)};
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealsList.text")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.dealsList.title")),
    };

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 20px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "row",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px",
          sm: "368px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "300px",
        },
      },
      header: {
        title: {
          fontFamily: titleTheme.v2.typography.title1.family,
          color: titleTheme.v2.color.primary,
          fontWeight: titleTheme.v2.typography.title1.weight,
          textTransform: titleTheme.v2.text.decorations.none,
          fontSize: {
            lg: titleTheme.v2.typography.title1.size.lg,
            md: titleTheme.v2.typography.title1.size.md,
            sm: titleTheme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: descriptionTheme.v2.typography.bodyText1.family,
            fontSize: {
              lg: descriptionTheme.v2.typography.bodyText1.size.lg,
              md: descriptionTheme.v2.typography.bodyText1.size.md,
              sm: descriptionTheme.v2.typography.bodyText1.size.md,
            },
            lineHeight: {
              lg: descriptionTheme.v2.typography.bodyText1.lineHeight,
              md: descriptionTheme.v2.typography.bodyText1.lineHeight,
              sm: descriptionTheme.v2.typography.bodyText1.lineHeight,
            },
          },
          button: {
            fontWeight: descriptionTheme.v2.typography.title6.weight,
            color: descriptionTheme.v2.color.onBackground,
          },
          text: {
            fontWeight: descriptionTheme.v2.typography.bodyText1.weight,
            color: descriptionTheme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "45px",
          backgroundColor: theme.v2.color.base.grey["300"],
          color: theme.v2.color.onBackground,
        },
      },
    };
  }

  getProductCardProps(_theme, props = {}) {
    const cacheKey =
      "product-card_" +
      JSON.stringify({
        mode: props.mode,
        isHorizontalInMobile: props.isHorizontalInMobile,
      });
    if (BoostThemeProps.cache[cacheKey]) {
      return BoostThemeProps.cache[cacheKey];
    }

    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productCard)};
    const imageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.image")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.flowerTypeIndicator")),
    };
    const rootTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.card")),
    };
    const actionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.actions")),
    };
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.potencyTag")),
    };
    const priceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.price")),
    };
    const strikePriceTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.strikePrice")),
    };
    const sizesTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.sizes")),
    };
    const infoTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.info")),
    };
    const saleIndicatorTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productCard.saleIndicator")),
    };

    const padding = {
      lg: props.mode === "small" ? "8px" : "24px",
      md: props.mode === "small" ? "8px" : "16px",
      sm: "8px",
    };

    const result = {
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        padding: {
          sm: props.isHorizontalInMobile && "8px 16px 8px 0",
        },
        height: {
          lg: props.mode === "small" ? "300px" : "430px",
          md: props.mode === "small" ? "300px" : "420px",
          sm: props.isHorizontalInMobile
            ? "200px"
            : props.mode === "small"
            ? "300px"
            : "412px",
        },
        maxWidth: {
          lg: "280px",
          md: "25vw",
          sm: props.isHorizontalInMobile ? "368px" : "50vw",
        },
        minWidth: {
          lg: props.mode === "small" ? "160px" : "150px",
          md: props.mode === "small" ? "160px" : "150px",
          sm: props.isHorizontalInMobile
            ? "300px"
            : props.mode === "small"
            ? "100%"
            : "130px",
        },
      },
      image: {
        borderBottom: "0",
        borderRadius: imageTheme.v2.decoration.border.radius.default,
        height: {
          lg: props.mode === "small" ? "150px" : "155px",
          md: props.mode === "small" ? "150px" : "155px",
          sm: props.isHorizontalInMobile
            ? "155px"
            : props.mode === "small"
            ? "150px"
            : "155px",
        },
        minWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        maxWidth: {
          sm: props.isHorizontalInMobile && "155px",
        },
        objectFit: "contain",
        padding: {
          lg: padding.lg,
          md: padding.md,
          sm: props.isHorizontalInMobile ? "0" : padding.sm,
        },
      },
      flowerTypeIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        borderRadius: flowerTypeIndicatorTheme.v2.decoration.border.radius.default,
        padding: props.mode === "small" ? "4px" : "6px 8px",
        fontSize: {
          lg: flowerTypeIndicatorTheme.v2.typography.bodyText2.size.lg,
          md: flowerTypeIndicatorTheme.v2.typography.bodyText2.size.sm,
          sm: flowerTypeIndicatorTheme.v2.typography.bodyText2.size.sm,
        },
        margin: {
          lg: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          md: props.mode === "small" ? "2px 20px 15px 0px" : "0",
          sm: props.mode === "small" ? "0px 0px 0 30px" : "0",
        },
        position: {
          bottom: "",
          left: props.mode === "small" ? "" : "",
          right: props.mode === "small" ? "0" : "8px",
          top: props.isHorizontalInMobile
            ? "0px"
            : props.mode === "small"
            ? "5px"
            : "8px",
        },
        color: flowerTypeIndicatorTheme.v2.color.background,
        textTransform: "none",
      },
      saleIndicator: {
        fontWeight: flowerTypeIndicatorTheme.v2.typography.title6.weight,
        borderRadius: flowerTypeIndicatorTheme.v2.decoration.border.radius.default,
        fontFamily: flowerTypeIndicatorTheme.v2.typography.title6.family,
        backgroundColor: saleIndicatorTheme.v2.color.primary,
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText2.size.md,
          md: brandNameTheme.v2.typography.bodyText2.size.sm,
          sm: brandNameTheme.v2.typography.bodyText2.size.sm,
        },
        top: props.isHorizontalInMobile ? "30px" : props.mode === "small" ? "10px" : 0,
        margin: {
          lg: props.mode === "small" ? "4px 8px 0 8px" : "32px",
          md: props.mode === "small" ? "4px 8px 0 8px" : "26px",
          sm: props.mode === "small" ? "4px 8px 0 8px" : "0px",
        },
      },
      info: {
        padding: {
          lg: `8px ${padding.lg} ${padding.lg} ${padding.lg}`,
          md: `8px ${padding.md} ${padding.md} ${padding.md}`,
          sm: props.isHorizontalInMobile ? "0" : padding.sm,
        },
        childrenMarginBottom: {
          lg: infoTheme.v2.spacing.size?.xs,
          md: infoTheme.v2.spacing.size?.xs,
          sm: infoTheme.v2.spacing.size?.xs,
        },
      },
      brandName: {
        color: brandNameTheme.v2.color.typography.bodyText2,
        fontFamily: brandNameTheme.v2.typography.bodyText2.family,
        fontSize: {
          lg: brandNameTheme.v2.typography.bodyText2.size.lg,
          md: brandNameTheme.v2.typography.bodyText2.size.lg,
          sm: brandNameTheme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: brandNameTheme.v2.typography.bodyText2.weight,
        textTransform: brandNameTheme.v2.typography.bodyText2.textCase,
      },
      productName: {
        color: productNameTheme.v2.color.onSurface,
        fontFamily: productNameTheme.v2.typography.title6.family,
        fontSize: {
          lg: productNameTheme.v2.typography.title6.size.lg,
          md: productNameTheme.v2.typography.title6.size.lg,
          sm: productNameTheme.v2.typography.title6.size.lg,
        },
        fontWeight: productNameTheme.v2.typography.title6.weight,
        textTransform: productNameTheme.v2.typography.title6.textCase,
      },
      potencyTag: {
        fontFamily: potencyTagTheme.v2.typography.caption1.family,
        backgroundColor: potencyTagTheme.v2.color.secondary,
        border: 0,
        borderRadius: potencyTagTheme.v2.decoration.border.radius.default,
        fontSize: {
          lg: potencyTagTheme.v2.typography.caption1.size.lg,
          md: potencyTagTheme.v2.typography.caption1.size.sm,
          sm: potencyTagTheme.v2.typography.caption1.size.sm,
        },
        color: potencyTagTheme.v2.color.typography.caption1,
        padding: {
          lg: "4px 8px",
          md: "4px 8px",
          sm: "2px 4px",
        },
      },
      productSizes: {
        color: sizesTheme.v2.color.base.grey["600"],
        fontFamily: sizesTheme.v2.typography.caption1.family,
        fontSize: {
          lg: sizesTheme.v2.typography.caption1.size.lg,
          md: sizesTheme.v2.typography.caption1.size.lg,
          sm: sizesTheme.v2.typography.caption1.size.lg,
        },
        fontWeight: sizesTheme.v2.typography.caption1.weight,
      },
      actionsContainer: {
        padding: {
          lg: `0 ${padding.lg} ${padding.lg} ${padding.lg}`,
          md: `0 ${padding.md} ${padding.md} ${padding.md}`,
          sm: props.isHorizontalInMobile ? "0" : `0px 10px ${padding.md} 10px`,
        },
      },
      actions: {
        root: {
          fontFamily: theme.v2.typography.title6.family,
          fontWeight: theme.v2.typography.title6.weight,
        },
        actions: {
          backgroundColor: theme.v2.color.primary,
          color: actionsTheme.v2.color.onPrimary,
          borderRadius: actionsTheme.v2.decoration.border.radius.default,
          height: {
            lg: "64px",
            md: "48px",
            sm: "48px",
          },
        },
        prices: {
          position: {
            lg: "left",
            md: "left",
            sm: "left",
          },
          crossed: {
            fontSize: {
              lg: strikePriceTheme.v2.typography.bodyText2.size.lg,
              md: strikePriceTheme.v2.typography.bodyText2.size.lg,
              sm: strikePriceTheme.v2.typography.bodyText2.size.lg,
            },
            fontWeight: strikePriceTheme.v2.typography.bodyText2.weight,
            color:
              props.mode === "small"
                ? strikePriceTheme.v2.color.primary
                : strikePriceTheme.v2.color.onBackground,
          },
          alignment: "column-reverse",
          fontSize: {
            lg: priceTheme.v2.typography.title6.size.lg,
            md: priceTheme.v2.typography.title6.size.lg,
            sm: priceTheme.v2.typography.title6.size.sm,
          },
          fontWeight: priceTheme.v2.typography.title6.weight,
          color:
            props.mode === "small"
              ? actionsTheme.v2.color.primary
              : actionsTheme.v2.color.onBackground,
        },
      },
    };

    BoostThemeProps.cache[cacheKey] = result;
    return result;
  }

  getDealDetailProps(_theme) {
    return this.defaultThemeProps.getDealDetailProps(_theme);
  }

  getProductDetailProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail)};
    const brandNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.brandName")),
    };
    const productNameTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.productName")),
    };
    const potencyTagTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.potencyTag")),
    };
    const secondaryTagsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.secondaryTags")),
    };
    const flowerTypeIndicatorTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.flowerTypeIndicator")
      ),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.description")),
    };
    const priceDisplayTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.priceDisplay")),
    };

    const quantityControllersTheme = {
      v2: merge(
        {},
        theme.v2,
        get(theme, "v2.overrides.productDetail.quantityControllers")
      ),
    };

    const navigationActionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productDetail.navigationActions")),
    };

    return {
      brand: {
        fontSize: {
          lg: get(brandNameTheme, "v2.typography.title6.size.lg"),
          md: get(brandNameTheme, "v2.typography.title6.size.lg"),
          sm: get(brandNameTheme, "v2.typography.title6.size.lg"),
        },
        fontWeight: get(brandNameTheme, "v2.typography.title6.weight"),
        color: get(brandNameTheme, "v2.color.typography.title6"),
        hoverTextDecoration: get(brandNameTheme, "v2.text.decorations.underline"),
        textTransform: get(brandNameTheme, "v2.typography.title6.textCase"),
      },
      name: {
        fontFamily: get(productNameTheme, "v2.typography.title2.family"),
        fontSize: get(productNameTheme, "v2.typography.title2.size"),
        fontWeight: get(productNameTheme, "v2.typography.title2.weight"),
        color: get(productNameTheme, "v2.color.onSurface"),
        textTransform: get(productNameTheme, "v2.typography.title2.textCase"),
      },
      mainTagsContainer: {
        columnGap: "4px",
        margin: "10px 0 0 0",
      },
      flowerTypeIndicator: {
        padding: get(flowerTypeIndicatorTheme, "v2.static.spacing.size.s"),
        border: "none",
        borderRadius: get(
          flowerTypeIndicatorTheme,
          "v2.decoration.border.radius.default"
        ),
        textTransform: get(flowerTypeIndicatorTheme, "v2.text.case.uppercase"),
        fontSize: {
          lg: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          md: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.lg"),
          sm: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.size.md"),
        },
        fontWeight: get(flowerTypeIndicatorTheme, "v2.typography.bodyText1.weight"),
      },
      mainTags: {
        padding: {
          lg: get(potencyTagTheme, "v2.static.spacing.size.s"),
          md: get(potencyTagTheme, "v2.static.spacing.size.s"),
          sm: get(potencyTagTheme, "v2.static.spacing.size.s"),
        },
        border: "none",
        borderRadius: get(potencyTagTheme, "v2.decoration.border.radius.default"),
        backgroundColor: get(potencyTagTheme, "v2.color.secondary"),
        fontSize: {
          lg: get(potencyTagTheme, "v2.typography.bodyText2.size.lg"),
          md: get(potencyTagTheme, "v2.typography.bodyText2.size.lg"),
          sm: get(potencyTagTheme, "v2.typography.bodyText2.size.lg"),
        },
        fontFamily: get(potencyTagTheme, "v2.typography.bodyText2.family"),
        fontWeight: get(potencyTagTheme, "v2.typography.bodyText2.weight"),
        color: get(potencyTagTheme, "v2.color.typography.bodyText2"),
      },
      description: {
        root: {
          margin: "6px 0",
          fontFamily: get(descriptionTheme, "v2.typography.bodyText1.family"),
          lineHeight: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            md: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.lineHeight"),
          },
        },
        text: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            md: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(descriptionTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.bodyText1.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
        button: {
          fontSize: {
            lg: get(descriptionTheme, "v2.typography.title6.size.lg"),
            md: get(descriptionTheme, "v2.typography.title6.size.lg"),
            sm: get(descriptionTheme, "v2.typography.title6.size.lg"),
          },
          fontWeight: get(descriptionTheme, "v2.typography.title6.weight"),
          color: get(descriptionTheme, "v2.color.base.grey.800"),
        },
      },
      priceDisplay: {
        price: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.title5.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.title5.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
        size: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.bodyText1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.bodyText1.weight"),
          color: get(priceDisplayTheme, "v2.color.base.grey.600"),
        },
        pricePerUnit: {
          fontSize: {
            lg: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            md: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
            sm: get(priceDisplayTheme, "v2.typography.caption1.size.lg"),
          },
          fontWeight: get(priceDisplayTheme, "v2.typography.caption1.weight"),
          color: get(priceDisplayTheme, "v2.color.onSurface"),
        },
      },
      quantityPicker: {
        root: {
          width: {
            sm: "100vw",
          },
          padding: {
            sm: `0 ${get(theme, "v2.static.spacing.size.xs")}`,
          },
        },
        quantityPicker: {
          borderRadius: get(theme, "v2.decoration.border.radius.default"),
          backgroundColor: get(theme, "v2.color.base.grey.200"),
          quantityColor: get(theme, "v2.color.onSurface"),
          controllersColor: get(quantityControllersTheme, "v2.color.onSurface"),
          quantitySize: get(theme, "v2.typography.title4.size"),
          quantityWeight: get(theme, "v2.typography.title4.weight"),
          controllersWidth: {
            lg: get(theme, "v2.static.spacing.size.xl"),
            md: get(theme, "v2.static.spacing.size.xl"),
            sm: get(theme, "v2.static.spacing.size.xl"),
          },
          controllersHeight: {
            lg: get(theme, "v2.static.spacing.size.xl"),
            md: get(theme, "v2.static.spacing.size.xl"),
            sm: get(theme, "v2.static.spacing.size.xl"),
          },
          minusIconSize: get(theme, "v2.static.spacing.size.xl"),
          plusIconSize: get(theme, "v2.static.spacing.size.xl"),
          removeItemIcon: {
            color: get(theme, "v2.color.onSurface"),
          },
        },
        addToCartButton: {
          backgroundColor: get(theme, "v2.color.primary"),
          color: get(theme, "v2.color.onPrimary"),
          borderColor: get(theme, "v2.color.primary"),
          borderRadius: get(theme, "v2.decoration.border.radius.default"),
          fontSize: get(theme, "v2.typography.title5.size"),
          fontWeight: get(theme, "v2.typography.title5.weight"),
        },
      },
      secondaryTags: {
        backgroundColor: get(secondaryTagsTheme, "v2.color.secondary"),
        color: get(secondaryTagsTheme, "v2.color.base.grey.600"),
        borderRadius: theme.v2.decoration.border.radius.xl,
        fontSize: {
          lg: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          md: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
          sm: get(secondaryTagsTheme, "v2.typography.caption1.size.lg"),
        },
        fontWeight: get(secondaryTagsTheme, "v2.typography.caption1.weight"),
        textTransform: get(theme, "v2.text.case.capitalize"),
        padding: {
          lg: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          md: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
          sm: `${get(theme, "v2.static.spacing.size.xs")} ${get(
            theme,
            "v2.static.spacing.size.s"
          )}`,
        },
      },
      navigationActions: {
        backButton: {
          backgroundColor: navigationActionsTheme.v2.color.base.grey["400"],
          color: navigationActionsTheme.v2.color.base.grey["900"],
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
        cartButton: {
          backgroundColor: navigationActionsTheme.v2.color.primary,
          color: navigationActionsTheme.v2.color.onPrimary,
          borderRadius: navigationActionsTheme.v2.decoration.border.radius.default,
          fontWeight: navigationActionsTheme.v2.typography.title5.weight,
        },
      },
    };
  }

  getProductDetailSeparatorProps(_theme) {
    const theme = {
      v2: merge({}, _theme.v2, _theme.v2.overrides.productDetail?.separator),
    };

    return {
      backgroundColor: theme.v2.color.base.grey["200"],
    };
  }

  getProductListProps(_theme, site) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productList)};

    return {
      root: {
        elementsPerRow: {
          lg: site.getUiConfiguration().showcasedProductsList.productsPerLine.lg,
          md: site.getUiConfiguration().showcasedProductsList.productsPerLine.md,
          sm: 1,
        },
      },
      element: {},
      header: {
        title: {
          fontFamily: theme.v2.typography.title1.family,
          color: theme.v2.color.primary,
          fontWeight: theme.v2.typography.title1.weight,
          textTransform: theme.v2.text.decorations.none,
          fontSize: {
            lg: theme.v2.typography.title1.size.lg,
            md: theme.v2.typography.title1.size.md,
            sm: theme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v2.typography.bodyText1.family,
            fontSize: {
              lg: theme.v2.typography.bodyText1.size.lg,
              md: theme.v2.typography.bodyText1.size.md,
              sm: theme.v2.typography.bodyText1.size.md,
            },
            lineHeight: {
              lg: theme.v2.typography.bodyText1.lineHeight,
              md: theme.v2.typography.bodyText1.lineHeight,
              sm: theme.v2.typography.bodyText1.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v2.typography.title6.weight,
            color: theme.v2.color.onBackground,
          },
          text: {
            fontWeight: theme.v2.typography.bodyText1.weight,
            color: theme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
    };
  }

  getNoResultsPlaceholderProps(theme) {
    return {
      root: {
        padding: {
          lg: "24px 8px",
          md: "24px 8px",
          sm: "24px 8px",
        },
      },
      logo: {
        backgroundColor: theme.v2.color.primary,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      titleText: {
        fontFamily: theme.v2.typography.title1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.l,
      },
      detailText: {
        fontFamily: theme.v2.typography.bodyText1.family,
        color: theme.v2.color.onBackground,
        fontSize: theme.v2.typography.sizing.m,
      },
    };
  }

  getShowcasedProductListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.showcasedProductsList)};

    return {
      list: {
        justifyContent: "flex-start",
        padding: "14px 16px 42px 20px",
        flexDirection: {
          lg: "row",
          md: "row",
          sm: "column",
        },
      },
      element: {
        maxWidth: {
          lg: "320px",
          md: "250px",
          sm: "368px",
        },
        minWidth: {
          lg: "150px",
          md: "140px",
          sm: "300px",
        },
      },
      header: {
        title: {
          fontFamily: theme.v2.typography.title1.family,
          color: theme.v2.color.primary,
          fontWeight: theme.v2.typography.title1.weight,
          textTransform: theme.v2.text.decorations.none,
          fontSize: {
            lg: theme.v2.typography.title1.size.lg,
            md: theme.v2.typography.title1.size.md,
            sm: theme.v2.typography.title1.size.md,
          },
          backgroundPadding: {
            lg: "16px",
            md: "8px",
            sm: "8px",
          },
        },
        description: {
          root: {
            fontFamily: theme.v2.typography.bodyText1.family,
            fontSize: {
              lg: theme.v2.typography.bodyText1.size.lg,
              md: theme.v2.typography.bodyText1.size.md,
              sm: theme.v2.typography.bodyText1.size.md,
            },
            lineHeight: {
              lg: theme.v2.typography.bodyText1.lineHeight,
              md: theme.v2.typography.bodyText1.lineHeight,
              sm: theme.v2.typography.bodyText1.lineHeight,
            },
          },
          button: {
            fontWeight: theme.v2.typography.title6.weight,
            color: theme.v2.color.onBackground,
          },
          text: {
            fontWeight: theme.v2.typography.bodyText1.weight,
            color: theme.v2.color.onBackground,
            maxLines: {
              lg: 3,
              md: 3,
              sm: 3,
            },
          },
        },
      },
      indicator: {
        indicator: {
          color: theme.v2.color.primary,
        },
      },
      navigationButton: {
        root: {
          width: "45px",
          backgroundColor: theme.v2.color.base.grey["300"],
          color: theme.v2.color.onBackground,
        },
      },
    };
  }

  getAppliedFiltersListProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appliedFilters)};

    return {
      root: {
        margin: `0 ${theme.v2.spacing(4)} ${theme.v2.spacing(1)} 0`,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
        color: theme.v2.color.onSecondary,
      },
      text: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: {
          lg: theme.v2.typography.bodyText1.size.lg,
          md: theme.v2.typography.bodyText1.size.md,
          sm: theme.v2.typography.bodyText1.size.sm,
        },
        fontWeight: theme.v2.typography.bodyText1.weight,
      },
    };
  }

  getSortFiltersProps(_theme, options = {}) {
    const theme = {v2: merge({}, _theme.v2, get(_theme.v2, "overrides.sortFilters"))};
    const optionsTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.sortFilters.options")),
    };

    return {
      root: {
        padding: {
          lg: `0 0 ${theme.v2.spacing(8)} ${theme.v2.spacing(8)}`,
          md: `0 0 ${theme.v2.spacing(4)} ${theme.v2.spacing(4)}`,
        },
      },
      sort: {
        root: {
          width: {
            lg: "230px",
            sm: "190px",
          },
          control: {
            borderColor: theme.v2.color.border,
            borderRadius: theme.v2.decoration.border.radius.default,
            borderWidth: {
              lg: "3px",
              md: "3px",
              sm: options.withBorder?.sm ? "3px" : "0",
            },
            height: {
              sm: "50px",
            },
            color: theme.v2.color.onSurface,
            backgroundColor: theme.v2.color.surface,
            fontFamily: theme.v2.typography.title5.family,
            fontSize: {
              lg: theme.v2.typography.title5.size.lg,
            },
            fontWeight: theme.v2.typography.title5.weight,
            lineHeight: theme.v2.typography.title5.lineHeight,
            hover: {
              borderColor: theme.v2.color.primary,
            },
          },
          menu: {
            borderRadius: theme.v2.decoration.border.radius.default,
            backgroundColor: theme.v2.color.surface,
            borderColor: theme.v2.color.border,
            margin: `${theme.v2.static.spacing.size.xs} 0`,
          },
          options: {
            color: theme.v2.color.onBackground,
            fontFamily: optionsTheme.v2.typography.title5.family,
            fontSize: {
              lg: optionsTheme.v2.typography.title5.size.lg,
            },
            fontWeight: optionsTheme.v2.typography.title5.weight,
            hover: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
            selected: {
              backgroundColor: optionsTheme.v2.color.secondary,
              color: optionsTheme.v2.color.onSurface,
            },
          },
          arrow: {
            borderColor: `${theme.v2.color.onSurface} transparent transparent`,
            open: {
              borderColor: `transparent transparent ${theme.v2.color.onSurface}`,
            },
          },
        },
      },
      filters: {
        button: {
          root: {
            fontFamily: theme.v2.typography.title5.family,
            fontSize: theme.v2.typography.title5.size.lg,
            borderRadius: theme.v2.decoration.border.radius.default,
            border: {
              lg: `3px solid ${theme.v2.color.border}`,
              md: `3px solid ${theme.v2.color.border}`,
              sm: options.withBorder?.sm ? `3px solid ${theme.v2.color.border}` : "0",
            },
            fontWeight: theme.v2.typography.title5.weight,
            hoverBorderColor: theme.v2.color.primary,
            boxShadow: {
              sm: "0 0 20px -5px rgba(0, 0, 0, 0.2)",
            },
          },
          secondary: {
            backgroundColor: theme.v2.color.surface,
            color: theme.v2.color.onSurface,
          },
        },
      },
    };
  }

  getFooterProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.footer)};
    return {
      root: {
        backgroundColor: theme.v2.color.base.grey["50"],
      },
      element: {
        hoverLinkColor: theme.v2.color.primary,
        activeLinkColor: theme.v2.color.primary,
        fontFamily: theme.v2.typography.primary.family,
        color: theme.v2.color.onBackground,
      },
    };
  }

  getCardProps(theme, rootTheme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        borderRadius: rootTheme.v2.decoration.border.radius.default,
        boxShadow: applyShadow(get(theme, "v2.decoration.shadow.m", [])),
        hoverBorder: `${theme.v2.decoration.border.width.s} solid ${theme.v2.color.primary}`,
      },
    };
  }

  getDealCardProps(theme) {
    const rootTheme = {v2: merge({}, theme.v2, theme.v2.overrides.dealCard)};
    return {
      ...this.defaultThemeProps.getDealCardProps(theme),
      root: {
        ...this.getCardProps(theme, rootTheme).root,
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "130px",
        },
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "250px",
        },
        padding: {
          lg: "12px",
          md: "12px",
          sm: "12px",
        },
      },
      type: {
        fontSize: {
          lg: theme.v2.typography.bodyText2.size.lg,
          md: theme.v2.typography.bodyText2.size.lg,
          sm: theme.v2.typography.bodyText2.size.lg,
        },
        fontWeight: theme.v2.typography.bodyText2.weight,
        fontFamily: theme.v2.typography.bodyText2.family,
        color: theme.v2.color.typography.bodyText2,
      },
      title: {
        fontSize: {
          lg: theme.v2.typography.title6.size.lg,
          md: theme.v2.typography.title6.size.lg,
          sm: theme.v2.typography.title6.size.lg,
        },
        fontWeight: theme.v2.typography.title6.weight,
        color: theme.v2.color.onSurface,
        fontFamily: theme.v2.typography.title6.family,
      },
    };
  }

  getRecommendedListProps(theme) {
    return {
      list: {justifyContent: "flex-start"},
      element: {
        maxWidth: {
          lg: "250px",
          md: "250px",
          sm: "360px",
        },
        minWidth: {
          lg: "150px",
          md: "150px",
          sm: "150px",
        },
      },
      header: {},
      indicator: {
        indicator: {
          color: theme.v2.color.primaryVariant,
        },
      },
      navigationButton: {
        root: {},
      },
    };
  }

  getBrandHeaderProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, get(_theme, "v2.overrides.title"))};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.title")),
    };
    return {
      fontFamily: titleTheme.v2.typography.title1.family,
      fontSize: {
        sm: titleTheme.v2.typography.sizing["3xl"].lg,
        md: titleTheme.v2.typography.sizing["4xl"].lg,
        lg: titleTheme.v2.typography.sizing["4xl"].lg,
      },
      letterSpacing: {
        sm: titleTheme.v2.typography.title1.letterSpacing,
        md: titleTheme.v2.typography.title1.letterSpacing,
        lg: titleTheme.v2.typography.title1.letterSpacing,
      },
      fontWeight: titleTheme.v2.typography.primary.weight.heavy,
      color: titleTheme.v2.color.typography.title1,
      margin: 0,
      padding: 0,
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "> span": ` {
      cursor: default;
      font-family: ${titleTheme.v2.typography.body.family};
  
      &:hover {
        text-decoration: none;
      }
    }`,
    };
  }

  getProductRecommendationsProps() {
    return {
      element: {
        minWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
        maxWidth: {
          lg: "180px",
          md: "180px",
          sm: "180px",
        },
      },
    };
  }

  getProductFiltersProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides.productFilters)};
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.title")),
    };
    const counterTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.counter")),
    };
    const sectionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.productFilters.section")),
    };

    return {
      title: {
        backgroundColor: titleTheme.v2.color.background,
        color: titleTheme.v2.color.onBackground,
      },
      counter: {
        color: counterTheme.v2.color.onSurface,
      },
      section: {
        color: sectionTheme.v2.color.onSurface,
        separatorColor: sectionTheme.v2.color.base.grey[300],
        separatorWidth: "1px",
        checkmarkColor: sectionTheme.v2.color.onBackground,
      },
    };
  }

  getSearchProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.appBar?.search)};
    const searchBarTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchBar")),
    };
    const searchPanelTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPanel")),
    };
    const searchPanelGroupTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.group")
      ),
    };
    const searchPanelTitleTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.title")
      ),
    };
    const searchPanelDescriptionTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.description")
      ),
    };
    const searchPanelSeeAllTheme = {
      v2: merge(
        {},
        searchPanelTheme.v2,
        get(theme, "v2.overrides.appBar.search.searchPanel.seeAll")
      ),
    };
    const searchPageTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.appBar.search.searchPage")),
    };

    return {
      root: {
        backgroundColor: theme.v2.color.secondary,
      },
      searchBar: {
        root: {
          padding: {
            lg: 0,
            md: 0,
            sm: 0,
          },
        },
        input: {
          backgroundColor: searchBarTheme.v2.color.surface,
          borderRadius: searchBarTheme.v2.decoration.border.radius.xs,
          border: `1px solid ${searchBarTheme.v2.color.onSecondary}`,
          fontFamily: searchBarTheme.v2.typography.bodyText1.family,
          fontWeight: searchBarTheme.v2.typography.bodyText1.weight,
          fontSize: searchBarTheme.v2.typography.bodyText1.size,
          color: searchBarTheme.v2.color.typography.bodyText1,
          placeholder: {
            color: searchBarTheme.v2.color.typography.bodyText1,
          },
          searchIcon: {
            color: searchBarTheme.v2.color.base.grey[600],
          },
        },
        close: {
          color: searchBarTheme.v2.color.onSecondary,
        },
      },
      searchPanel: {
        root: {
          backgroundColor: searchPanelTheme.v2.color.secondary,
        },
        groupName: {
          fontFamily: searchPanelGroupTheme.v2.typography.title4.family,
          fontWeight: searchPanelGroupTheme.v2.typography.title4.weight,
          fontSize: searchPanelGroupTheme.v2.typography.title4.size,
          color: searchPanelGroupTheme.v2.color.onSecondary,
        },
        image: {
          borderRadius: searchPanelTheme.v2.decoration.border.radius.xs,
        },
        title: {
          fontFamily: searchPanelTitleTheme.v2.typography.title6.family,
          fontWeight: searchPanelTitleTheme.v2.typography.title6.weight,
          fontSize: searchPanelTitleTheme.v2.typography.title6.size,
          color: searchPanelTitleTheme.v2.color.onSecondary,
        },
        description: {
          fontFamily: searchPanelDescriptionTheme.v2.typography.caption1.family,
          fontWeight: searchPanelDescriptionTheme.v2.typography.caption1.weight,
          fontSize: searchPanelDescriptionTheme.v2.typography.caption1.size,
          color: searchPanelDescriptionTheme.v2.color.onSecondary,
        },
        seeAll: {
          fontFamily: searchPanelSeeAllTheme.v2.typography.caption1.family,
          fontWeight: searchPanelSeeAllTheme.v2.typography.caption1.weight,
          fontSize: searchPanelSeeAllTheme.v2.typography.caption1.size,
          color: searchPanelSeeAllTheme.v2.color.onSecondary,
        },
      },
      searchPage: {
        header: {
          fontFamily: searchPageTheme.v2.typography.title6.family,
          fontWeight: searchPageTheme.v2.typography.title6.weight,
          fontSize: searchPageTheme.v2.typography.title6.size,
          color: searchPageTheme.v2.color.onSecondary,
        },
        tabs: {
          root: {
            padding: 0,
          },
          tab: {
            fontFamily: searchPageTheme.v2.typography.title5.family,
            fontWeight: searchPageTheme.v2.typography.title5.weight,
            fontSize: {
              lg: searchPageTheme.v2.typography.title5.size.lg,
              md: searchPageTheme.v2.typography.title5.size.md,
              sm: "14px",
            },
            color: searchPageTheme.v2.color.typography.onBackground,
            border: `3px solid ${searchPageTheme.v2.color.secondary}`,
            buttonSelectedBackgroundColor: searchPageTheme.v2.color.primary,
            buttonSelectedColor: searchPageTheme.v2.color.onPrimary,
            buttonBorder: `1px solid ${searchPageTheme.v2.color.primary}`,
          },
          separator: {
            color: searchPageTheme.v2.color.base.grey["200"],
          },
        },
        results: {
          fontFamily: searchPageTheme.v2.typography.bodyText2.family,
          fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
          fontSize: searchPageTheme.v2.typography.bodyText2.size,
          color: searchPageTheme.v2.color.typography.onBackground,
        },
        sort: {
          label: {
            fontFamily: searchPageTheme.v2.typography.bodyText2.family,
            fontWeight: searchPageTheme.v2.typography.bodyText2.weight,
            fontSize: searchPageTheme.v2.typography.bodyText2.size,
            color: searchPageTheme.v2.color.typography.onBackground,
          },
        },
        groupedProductsList: {
          elementsPerRow: {
            lg: 4,
            md: 3,
            sm: 1,
          },
        },
      },
    };
  }

  getAnnouncementMessageProps(_theme) {
    const theme = {v2: merge({}, _theme.v2, _theme.v2.overrides?.announcementBox)};
    const buttonTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.button")),
    };
    const titleTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.title")),
    };
    const descriptionTheme = {
      v2: merge({}, theme.v2, get(theme, "v2.overrides.announcementBox.description")),
    };

    return {
      root: {
        fontFamily: theme.v2.typography.default?.family,
        fontSize: theme.v2.typography.default?.size,
        fontWeight: theme.v2.typography.default?.weight,
        color: theme.v2.color.typography.default,
        backgroundColor: theme.v2.color.surface,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      container: {
        backgroundColor: theme.v2.color.secondary,
      },
      title: {
        fontFamily: titleTheme.v2.typography.default?.family,
        fontSize: titleTheme.v2.typography.default?.size,
        fontWeight: titleTheme.v2.typography.default?.weight,
        color: titleTheme.v2.color.typography.default,
      },
      description: {
        fontFamily: descriptionTheme.v2.typography.default?.family,
        fontSize: descriptionTheme.v2.typography.default?.size,
        fontWeight: descriptionTheme.v2.typography.default?.weight,
        color: descriptionTheme.v2.color.typography.default,
      },
      button: {
        fontFamily: buttonTheme.v2.typography.default?.family,
        fontSize: buttonTheme.v2.typography.default?.size,
        fontWeight: buttonTheme.v2.typography.default?.weight,
        color: buttonTheme.v2.color.typography.default,
      },
    };
  }

  getSubcategoriesProps(theme) {
    return {
      root: {
        fontFamily: theme.v2.typography.bodyText1.family,
        fontSize: theme.v2.typography.bodyText1.size,
        fontWeight: theme.v2.typography.bodyText1.weight,
        borderRadius: theme.v2.decoration.border.radius.default,
      },
      selected: {
        backgroundColor: theme.v2.color.primary,
        color: theme.v2.color.onPrimary,
      },
      unselected: {
        backgroundColor: theme.v2.color.base.grey["200"],
        color: theme.v2.color.onSurface,
      },
    };
  }
}

memoizeClassMethods(BoostThemeProps);
